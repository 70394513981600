<!--
 * @Author: Neko
 * @Date: 2021-01-07 14:30:59
 * @LastEditTime: 2021-03-23 11:20:39
 * @LastEditors: Neko
-->
<template>
  <div class="send-code">
    <el-button
      class="button"
      :disabled="isCounting"
      @click="onClickSendBtn"
    >
      {{ isCounting ? time + ' s' : $t('common.getCode') }}
    </el-button>
  </div>
</template>

<script>
import useCountdown from '@/composition/useCountdown.js'

export default {
  name: 'SendCode',

  props: {
    beforeStart: {
      type: Function,
      default: () => { return true }
    }
  },

  setup(props, { emit }) {
    const {
      isCounting,
      startCountdown,
      time
    } = useCountdown(60)

    const onClickSendBtn = _ => {
      const isStartCountdown = props.beforeStart()
      console.log(isStartCountdown)
      if (isStartCountdown) {
        emit('start')
        // if (!isCounting.value) return
        startCountdown()
      }
    }

    return {
      time,
      isCounting,

      onClickSendBtn
    }
  }
}
</script>

<style lang="scss" scoped>
.send-code {
  .button {
    min-width: 100px;
    height: 40px;
    padding: 0 12px;
    margin-left: 20px;
    border: none;
    border-radius: 20px;
    font-size: 14px;
    line-height: 22px;
    font-weight: normal;
    color: #fff;
    background: #034BAD;
  }
}
</style>
