<!--
 * @Author: Neko
 * @Date: 2021-03-20 13:49:39
 * @LastEditTime: 2021-10-18 14:50:39
 * @LastEditors: Please set LastEditors
-->
<template>
  <div class="account__container">
    <div class="account__container-wrap">

      <div class="account__container-header">
        <div class="header">
          <div class="header__wrap">
            <h2 class="header__title">
              <span class="title">{{ $t('account.accountUser') }}</span>
            </h2>
          </div>
        </div>
      </div>

      <div class="account__wrap">
        <div class="account__section">
          <div class="account__header">
            {{ $t('account.editEmail') }}
          </div>

          <el-form ref="emailFormRef" class="account__form" :model="emailForm" :rules="emailFormRuler">
            <el-form-item :label="$t('account.isEmail')">
              <el-input :value="userData.email" disabled />
            </el-form-item>

            <el-form-item :label="$t('account.newEmail')" prop="email">
              <el-input v-model="emailForm.email" />
            </el-form-item>

            <el-form-item :label="$t('account.newEmail')" prop="code">
              <el-input v-model="emailForm.code">
                <template slot="append">
                  <SendCode class="send-code" :before-start="onBeforeStart" @start="onSendCode" />
                </template>
              </el-input>
            </el-form-item>
          </el-form>

          <div class="account__button">
            <el-button class="button" type="primary" @click="onClickSaveEmailBtn">{{ $t('account.enterEdit') }}</el-button>
          </div>
        </div>

        <div class="account__section">
          <div class="account__header">
            {{ $t('account.editUserName') }}
          </div>

          <el-form ref="usernameFormRef" class="account__form" :model="usernameForm" :rules="usernameFormRuler">
            <el-form-item :label="$t('account.isUserName')">
              <el-input v-model="userData.username" disabled />
            </el-form-item>

            <el-form-item :label="$t('account.newUserName')" prop="nickname">
              <el-input v-model="usernameForm.nickname" :placeholder="$t('account.userNamePlaceholder')" :maxlength="32" show-word-limit />
            </el-form-item>
          </el-form>

          <div class="account__button">
            <el-button class="button" type="primary" @click="onClickSaveUsernameBtn">{{ $t('account.enterEdit') }}</el-button>
          </div>
        </div>

        <div class="account__section">
          <div class="account__header">
            {{ $t('account.editPwd') }}
          </div>

          <el-form ref="passwordFormRef" class="account__form" :model="passwordForm" :rules="passwordFormRuler">
            <el-form-item :label="$t('account.emails')" prop="email">
              <el-input :value="userData.email" disabled />
            </el-form-item>

            <el-form-item :label="$t('account.veriCode')" prop="validateCode">
              <el-input v-model="passwordForm.validateCode">
                <template slot="append">
                  <SendCode class="send-code" :before-start="onBeforeStartEmail" @start="onSendCode" />
                </template>
              </el-input>
            </el-form-item>

            <el-form-item ref="passwordFormItemRef" :label="$t('account.newPwd')" prop="password">
              <el-input v-model="passwordForm.password" type="password" />
            </el-form-item>

            <el-form-item ref="repasswordFormItemRef" :label="$t('account.enterPwd')" prop="rePassword">
              <el-input v-model="passwordForm.rePassword" type="password" />
            </el-form-item>
          </el-form>

          <div class="account__button">
            <el-button class="button" type="primary" @click="onClickSavePasswordBtn">{{ $t('account.enterSave') }}</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Base64 } from 'js-base64'
import SendCode from '@/components/Common/SendCode.vue'
import { computed, defineComponent, reactive, ref, toRefs, watch } from '@vue/composition-api'
import { Message } from 'element-ui'

import { useRequest } from '@/utils/request.js'

export default defineComponent({
  components: {
    SendCode
  },

  setup(_, { root }) {
    const store = root.$store
    const $t = root.$jst

    const emailFormRef = ref(null)
    const usernameFormRef = ref(null)
    const passwordFormRef = ref(null)
    const passwordFormItemRef = ref(null)
    const repasswordFormItemRef = ref(null)

    const checkRepeatValidator = (_, value, callback) => {
      if (data.passwordForm.password === data.passwordForm.rePassword) {
        passwordFormItemRef.value.clearValidate()
        repasswordFormItemRef.value.clearValidate()
        return callback()
      }

      if (data.passwordForm.password !== data.passwordForm.rePassword) {
        return callback(new Error($t('account.pwdErr')))
      }

      callback()
    }

    const data = reactive({
      emailForm: {
        email: '',
        code: ''
      },

      emailFormRuler: {
        email: [{ required: true, message: $t('account.input'), trigger: 'blur' }, { type: 'email', message: $t('account.emailErr'), trigger: 'blur' }],
        code: { required: true, message: $t('account.input'), trigger: 'blur' }
      },

      usernameForm: {
        nickname: ''
      },

      usernameFormRuler: {
        nickname: { required: true, message: $t('account.input'), trigger: 'blur' }
      },

      passwordForm: {
        password: '',
        rePassword: '',
        validateCode: '',
        email: computed(() => data.userData.email)
      },

      passwordFormRuler: {
        password: [{ required: true, message: $t('account.input'), trigger: 'blur' }, { validator: checkRepeatValidator, trigger: 'blur' }],
        rePassword: [{ required: true, message: $t('account.input'), trigger: 'blur' }, { validator: checkRepeatValidator, trigger: 'blur' }],
        validateCode: { required: true, message: $t('account.input'), trigger: 'blur' }
      },

      userData: computed(() => store.state.user.userData),

      validateType: '1'
    })

    const { fetch: sendEmailCodeFetch } = useRequest('account/sendEmailCodeAction', {
      data: computed(() => ({
        email: data.validateType === '1' ? data.emailForm.email : data.passwordForm.email
      })),

      immediate: false
    })

    const { data: changeEmailResult, fetch: changeEmailFetch } = useRequest('account/handleChangeEmailAction', {
      data: computed(() => data.emailForm),
      immediate: false,
      isCustom: true
    })

    const onSendCode = _ => {
      sendEmailCodeFetch()
    }

    const onBeforeStart = _ => {
      let result = false
      emailFormRef.value.validateField('email', res => {
        if (res) {
          Message.error($t('account.emailErr'))
          result = false
        } else {
          data.validateType = '1'
          result = true
        }
      })

      return result
    }

    const onBeforeStartEmail = _ => {
      let result = false
      passwordFormRef.value.validateField('email', res => {
        if (res) {
          Message.error($t('account.emailErr'))
          result = false
        } else {
          data.validateType = '2'
          result = true
        }
      })

      return result
    }

    const onClickSaveEmailBtn = _ => {
      emailFormRef.value.validate(isValid => {
        if (isValid) {
          changeEmailFetch()
        }
      })
    }

    watch(() => changeEmailResult.value, res => {
      if (res.code === 200) {
        Message.success($t('account.editSuccess'))
        store.commit('user/SET_USER_EMAIL', {
          email: data.emailForm.email
        })
        data.emailForm = {
          email: '',
          code: ''
        }
        emailFormRef.value.resetFields()
      }
    })

    const { data: handleSaveNicknameResult, fetch: handleSaveNicknameFetch } = useRequest('account/handleSaveNicknameAction', {
      data: computed(() => data.usernameForm),
      immediate: false,
      isCustom: true
    })

    const onClickSaveUsernameBtn = _ => {
      usernameFormRef.value.validate(isValid => {
        if (isValid) {
          handleSaveNicknameFetch()
        }
      })
    }

    watch(() => handleSaveNicknameResult.value, res => {
      if (res.code === 200) {
        Message.success($t('account.editSuccess'))
        store.commit('user/SET_USERNAME', {
          username: data.usernameForm.nickname
        })
        data.usernameForm = {
          nickname: ''
        }
        usernameFormRef.value.resetFields()
      }
    })

    const { data: handleSavePasswordResult, fetch: handleSavePasswordFetch } = useRequest('account/handleSavePasswordAction', {
      data: computed(() => Object.assign({}, data.passwordForm, {
        password: Base64.encode(data.passwordForm.password),
        rePassword: Base64.encode(data.passwordForm.rePassword)
      })),
      immediate: false,
      isCustom: true
    })

    const onClickSavePasswordBtn = _ => {
      passwordFormRef.value.validate(isValid => {
        if (isValid) {
          handleSavePasswordFetch()
        }
      })
    }

    watch(() => handleSavePasswordResult.value, res => {
      if (res.code === 200) {
        Message.success($t('account.editSuccess'))
        data.passwordForm = {
          password: '',
          rePassword: '',
          validateCode: '',
          email: computed(() => data.userData.email)
        }
        passwordFormRef.value.resetFields()
      }
    })

    return {
      ...toRefs(data),
      emailFormRef,
      usernameFormRef,
      passwordFormRef,
      passwordFormItemRef,
      repasswordFormItemRef,

      onBeforeStart,
      onSendCode,
      onClickSaveEmailBtn,

      onClickSaveUsernameBtn,

      onClickSavePasswordBtn,
      onBeforeStartEmail
    }
  }
})
</script>

<style lang="scss" scoped>
.account__container {
  padding: 25px 0 111px;

  .account__container-wrap {
    min-width: 1200px;
    width: 96.9%;
    max-width: 1600px;
    margin: 0 auto;
    box-shadow: 0px 17px 102px 15px rgba(232, 232, 234, 0.95);

    .account__container-header {
      .header {
        position: relative;
        z-index: 2;
        background: #fff;

        .header__wrap {
          position: relative;
          z-index: 2;
          background: #fff;
        }

        .header__title {
          width: 1200px;
          margin: 0 auto;
          letter-spacing: 2px;
          font-size: 18px;

          .title {
            display: inline-block;
            padding: 33px 0 26px;
            border-bottom: 2px solid #0049AC;
            color: #0D5CAB;
          }

        }

      }
    }
  }

  .account__wrap {
    display: flex;
    justify-content: space-between;
    width: 1200px;
    padding: 20px 0;
    margin: 0 auto;

    .account__section {
      width: 380px;
      padding: 40px;
      border-radius: 8px;
      background: #fff;

      .account__header {
        padding-bottom: 20px;
        border-bottom: 1px solid #EDEDED;
        font-size: 16px;
        font-weight: bolder;
        color: #222222;
      }

      .account__form {
        padding: 25px 0;
        margin-bottom: 60px;
      }

      .account__button {
        .button {
          min-width: 130px;
          height: 50px;
          background: #034BAD;
          border-radius: 25px;
          font-weight: normal;
        }
      }
    }
  }

  ::v-deep .el-input-group__append {
    border: none;
    background: #fff;
  }
}
</style>
